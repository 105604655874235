import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  addOccupancyLink: {
      overflow: "visible",
      width: "auto",
      fontSize: "1em",
      textAlign: "left",
      color: theme.palette.primary.main,
      background: "none",
      margin: 0,
      padding: 0,
      border: "none",
      cursor: "pointer",
      textDecoration: "underline"
  },

  availabilityTable: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
      width: "85%",
      order: 1,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      width: "85%",
      order: 1,
    },
  },

  bottomContainers: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  containerMargin: {
    margin: "10px",
  },

  countDoughnutsPaper: {
    width: "100%",
  },

  doughnuts: {
    [theme.breakpoints.up("lg")]: {
      width: "32%",
      marginLeft: "-70px",
    },
    [theme.breakpoints.only("md")]: {
      width: "30%",
      marginLeft: "-40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      order: 2,
    },
  },

  doughnutContainer: {
    marginLeft: "5%",
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  drawer: {
    width: "40%",
  },

  footerMargin: {
    margin: "20px",
  },

  linkOpenDrawer: {
    cursor: "pointer",
  },
}));