import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import apiClient from "../../../auth/apiClient";
import CountService from "../../../services/CountService";
import ThirdpartyVendorService from "../../../services/ThirdpartyVendorService";

import useHasPermissions from "../../../hooks/useHasPermissions";
import useHubContext from "../../../hooks/useHubContext";
import { ACCESS_GROUP_COUNT_CHANGE, OCCUPANCY_DEMAND_CHANGE } from "../../../constants";
import { camelizeKeys } from "../../../utils/camelizeKeys";

export const OccupancyDataContext = createContext();

const countService = new CountService(apiClient);
const thirdPartyService = new ThirdpartyVendorService(apiClient);

const OccupancyDataProvider = props => {

  const occupancyView = useHasPermissions(["dashboard.occupancy.view"]);
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const facilities = useSelector((state) => state.entityScope.selected) ?? [];
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);

  const [occupancyTypesLoaded, setOccupancyTypesLoaded] = useState(false);
  const [occupancyTypes, setOccupancyTypes] = useState([]);
  const loadOccupancyTypes = () => {
    if (!occupancyTypesLoaded) {
      countService.getOccupancyTypes()
        .then(result => {
          //Filtering out 'Valet Ticket' occupancy type as no related functionality applicable for Valet yet.
          result.data = result.data?.filter(x => x.description !== "Valet Ticket");
          setOccupancyTypes(result.data);
          setOccupancyTypesLoaded(true);
        })
        .catch(() => {
          return;
        });
    }
  };

  const [subscriberDataByFacility, setSubscriberDataByFacility] = useState({});
  const [countDataLoaded, setCountDataLoaded] = useState(false);
  const [countDataByFacility, setCountDataByFacility] = useState({});
  const loadOccupancyData = async (facilities) => {

    if (!facilities || facilities.length <= 0) { return; } 

    const subscriberData = {};
    const counts = {};

    for (const facility of facilities) {
      await loadOccupancyDataInternal(facility.id, subscriberData, counts);
    }
    
    setSubscriberDataByFacility(subscriberData);
    setCountDataByFacility(counts);
    setCountDataLoaded(true);
  };

  const loadOccupancyDataForFacility = async (facilityID) => {

    // clone to create new references to kick off useEffect dependency updates
    const subscriberData = _.cloneDeep(subscriberDataByFacility);
    const counts = _.cloneDeep(countDataByFacility);

    await loadOccupancyDataInternal(facilityID, subscriberData, counts);

    setSubscriberDataByFacility(subscriberData);
    setCountDataByFacility(counts);
    setCountDataLoaded(true);
  }

  const loadOccupancyDataInternal = async (facilityID, subscriberData, counts) => {

    let thirdPartyDonut = {
      occupied: 0,
      totalSpaces: 0,
      occupiedPercent: 0,
      description: "3rd Party Passes"
    };

    // load third party pass data
    subscriberData[facilityID] = [];
    try {
      const scopeAwareFacilityID = facilityGroupID || facilityID;
      let response = await thirdPartyService.getThirdPartyPassesPerSubscriber(scopeAwareFacilityID);
      if (response.data.length > 0) {
        response.data.forEach(element => {
          thirdPartyDonut.occupied += element.inCount;
          thirdPartyDonut.totalSpaces += element.totalCount;
        });
  
        thirdPartyDonut.occupiedPercent =
        (thirdPartyDonut.totalSpaces <= 0
          ? thirdPartyDonut.occupied
          : thirdPartyDonut.occupied / thirdPartyDonut.totalSpaces) * 100

        subscriberData[facilityID] = response.data;
      }
    } catch (error) {
      // TODO: is this empty set necessary? We seed to empty set.
      subscriberData[facilityID] = [];
    }

    let occupancyData;

    // load supply data
    counts[facilityID] = [];
    try {
      let response = await countService.GetOccupancySupplyByEntity(facilityID);
      //Filtering out 'Valet Ticket' occupancy type as no related functionality applicable for Valet yet.
      occupancyData = response.data?.filter(x => x.occupancyType !== "Valet Ticket");

      // set occupancy supply descriptions to loaded type name
      if (occupancyTypes.length > 0) {
        occupancyData.forEach(row => {
          let type = occupancyTypes.find(
            x => x.occupancyTypeID === row.occupancyTypeID
          ).description;

          if (type != null) {
            row.description = type;
          }
        });
      }
    } catch (error) {
      // TODO: is this empty set necessary? We seed to empty set.
      counts[facilityID] = [];
      return;
    }

    // load demand data
    try {
      let response = await countService.GetOccupancyDemandByEntity(facilityID);
      for (const row of occupancyData) {
        let demand = response.data.find(
          x => x.occupancySupplyId == row.occupancySupplyID
        );

        if (demand != null) {
          row.occupied = demand.occupied;
          row.occupiedPercent = (row.occupied / row.totalSpaces) * 100;
          row.occupancyDemandId = demand.occupancyDemandId;
        } else {
          row.occupied = 0;
          row.occupiedPercent = 0;
        }
      }
      occupancyData.push(thirdPartyDonut);
      counts[facilityID] = occupancyData;

    } catch (error) {
      if (occupancyData?.length > 0) {
        for (const row of occupancyData) {
          row.occupied = 0;
          row.occupiedPercent = 0;
        }
        occupancyData.push(thirdPartyDonut);
        counts[facilityID] = occupancyData;
      } else {
        counts[facilityID] = [];
      }
    }
  }

  const populateOccupancyChange = (facilityID, existingCounts, updateCounts) => {
    let newCounts = _.cloneDeep(existingCounts);
    let facilityCounts = newCounts[facilityID];

    let index = facilityCounts.findIndex(
      (a) =>
        a.entityID == updateCounts.EntityID &&
        a.occupancySupplyID == updateCounts.OccupancySupplyId &&
        a.occupancyTypeID == updateCounts.OccupancyTypeId
    );

    if (index !== -1) {
      facilityCounts[index].occupancyDemandId = updateCounts.OccupancyDemandId;
      facilityCounts[index].entityID = updateCounts.EntityID;
      facilityCounts[index].occupied = updateCounts.Occupied;
      facilityCounts[index].occupiedPercent =
        (updateCounts.Occupied / facilityCounts[index].totalSpaces) * 100;
    }
    setCountDataByFacility(newCounts);
  }

  const [accessGroupDataByFacility, setAccessGroupDataByFacility] = useState({});
  const requestAccessGroupData = async (facilityID) => {

    if (accessGroupDataByFacility[facilityID] !== undefined) { 
      return; 
    }

    const response = await countService.GetTrackedAccessGroups(facilityID);
    const accessGroupInfo = {};
    accessGroupInfo.data = response.data;
    accessGroupInfo.loaded = true;
    const accessGroupCounts = _.cloneDeep(accessGroupDataByFacility)
    accessGroupCounts[facilityID] = accessGroupInfo;
    setAccessGroupDataByFacility(accessGroupCounts);

    // TODO: note that this message is not coming across when using the simulator. It
    // appears to only get received when an AccessHolder's status is manually changed
    // to Out in the portal. Changing to In also does NOT seem to fire this. However,
    // the functionality here has not changed. Will be investgated further in the near future.
    portalHub.subscribe(
      `${facilityID}_${ACCESS_GROUP_COUNT_CHANGE}`,
      (message) => {
        var rawData = JSON.parse(message);
        var data = JSON.parse(rawData.Message);
        var camalizedData = camelizeKeys(data.AccessGroupCounts);
        camalizedData.loaded = true;
        accessGroupDataByFacility[facilityID] = camalizedData;
        setAccessGroupDataByFacility(accessGroupDataByFacility);
      }
    );
  };

  // subscribe to changes once data is loaded
  useEffect(() => {
    if (!countDataLoaded || !PortalHubConnected || !occupancyView) { return; }
      
    for (let facility of facilities) {
      portalHub.subscribe(
        `${facility.id}_${OCCUPANCY_DEMAND_CHANGE}`,
        (message) => {
          var rawData = JSON.parse(message);
          var data = JSON.parse(rawData.Message);
          populateOccupancyChange(facility.id, countDataByFacility, data);
        }
      );
    }

    return () => {
      for (let facility of facilities) {
        portalHub.unsubscribe(`${facility.id}_${OCCUPANCY_DEMAND_CHANGE}`);
      }
    };
  }, [countDataLoaded, countDataByFacility, PortalHubConnected, facilities]);

  // pure destructor to unsub from any possible access group change data
  useEffect(() => {
    return () => {
      for (const facility of facilities) {
        if (accessGroupDataByFacility[facility.id]?.loaded) {
          portalHub.unsubscribe(`${facility.id}_${ACCESS_GROUP_COUNT_CHANGE}`);
        }
      }
    }
  }, [accessGroupDataByFacility, PortalHubConnected, facilities]);

  return (
    <OccupancyDataContext.Provider
    value={{
      occupancyTypes,
      occupancyTypesLoaded,
      loadOccupancyTypes,
      subscriberDataByFacility,
      countDataByFacility,
      countDataLoaded,
      loadOccupancyData,
      loadOccupancyDataForFacility,
      accessGroupDataByFacility,
      requestAccessGroupData
    }}
    >
      {props.children}
    </OccupancyDataContext.Provider>
  )
};

export default OccupancyDataProvider;

export function useOccupancyDataContext() {
  return useContext(OccupancyDataContext);
}