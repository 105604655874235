import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { Typography, Grid, TextField, Button, Divider } from "@material-ui/core";
import { ColorPicker } from "material-ui-color";

const TextCombo = ({ label, valueChange, colorChange, value, color, name }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={10} md={3} lg={8} className={classes.bottomMargin}>
        <TextField
          variant="outlined"
          type="number"
          label={label}
          name={name}
          data-id="Transient"
          className={clsx(`${name}-percentage`, classes.input)}
          size="small"
          value={value}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            let val = Math.floor(e.target.value);
            if (val > 100) val = 100;
            if(val < 0) val = 0;
            valueChange(val);
          }}
        />
      </Grid>
      <Grid item xs={2} md={8} lg={4} className={clsx(`${name}-color-picker`)} align="left">
        <ColorPicker
          value={color}
          hideTextfield
          onChange={(e) => {
            colorChange("#" + e.hex);
          }}
        />
      </Grid>
    </>
  );
};

const OccupancyThemes = ({ occupancyThemeData, themeDataChange }) => {
  const classes = useStyles();
  const [thresholdData, setThresholdData] = useState([
    {
      name: "contract",
      low: { name: "low", value: 0, color: "#4caf50" },
      medium: { name: "medium", value: 0, color: "#ffeb3b" },
      high: { name: "high", value: 0, color: "#d50000" },
    },
    {
      name: "transient",
      low: { name: "low", value: 0, color: "#4caf50" },
      medium: { name: "medium", value: 0, color: "#ffeb3b" },
      high: { name: "high", value: 0, color: "#d50000" },
    },
    {
      name: "facility",
      low: { name: "low", value: 0, color: "#4caf50" },
      medium: { name: "medium", value: 0, color: "#ffeb3b" },
      high: { name: "high", value: 0, color: "#d50000" },
    },
  ]);

  const thresholdChange = (type, alterFunc) => {
    let curState = thresholdData.slice();
    let index = curState.findIndex(x => x.name == type)

    alterFunc(curState[index])

    themeDataChange(curState);
  }

  useEffect(() => {
    if(occupancyThemeData?.length > 0) {
      setThresholdData(occupancyThemeData);
    }
    
  }, [occupancyThemeData]);

  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  };

  return (
    <Grid container xs={12} className={(clsx("preferences-drawer"), classes.preferencesContainer)}>
      <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h5" color="primary" component="h2" data-id="EditFormTitle" name="title">
              Occupancy Threshold Colors
            </Typography>
            <Divider style={{ marginBottom: 15 }}></Divider>
            <Grid container xs={12} md={12} lg={12}>
              {thresholdData?.map((row) => {
                return (
                  <>
                    <Grid container lg={4} className={clsx(`${row.name}-container`)}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography
                          variant="h6"
                          color="primary"
                          component="h5"
                          data-id="EditFormTitle"
                          name="title"
                          className={clsx("title")}
                        >
                          {capitalize(row.name)}
                        </Typography>
                      </Grid>
                      <TextCombo
                        label="Low %"
                        valueChange={(value) => {
                          thresholdChange(row.name, (r) => (r.low.value = value));
                        }}
                        colorChange={(value) => {
                          thresholdChange(row.name, (r) => (r.low.color = value));
                        }}
                        value={row.low.value}
                        color={row.low.color}
                        name="low"
                      />
                      <TextCombo
                        label="Medium %"
                        valueChange={(value) => {
                          thresholdChange(row.name, (r) => (r.medium.value = value));
                        }}
                        colorChange={(value) => {
                          thresholdChange(row.name, (r) => (r.medium.color = value));
                        }}
                        value={row.medium.value}
                        color={row.medium.color}
                        name="medium"
                      />
                      <TextCombo
                        label="High %"
                        valueChange={(value) => {
                          thresholdChange(row.name, (r) => (r.high.value = value));
                        }}
                        colorChange={(value) => {
                          thresholdChange(row.name, (r) => (r.high.color = value));
                        }}
                        value={row.high.value}
                        color={row.high.color}
                        name="high"
                      />
                      <Button 
                        size="small" 
                        color="primary"
                        className={clsx("reset-colors-button")}
                        onClick={(value) => {
                          thresholdChange(row.name, (r) => { r.low.color = "#4caf50"});
                          thresholdChange(row.name, (r) => { r.medium.color = "#ffeb3b"});
                          thresholdChange(row.name, (r) => { r.high.color = "#d50000" });
                        }}
                        >Default colors</Button>
                    </Grid>
                  </>
                );
              })}
            </Grid>
      </Grid>
    </Grid>
  );
};

export default OccupancyThemes;
