import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { grey, purple, teal, orange, green, cyan, red, blue, lightGreen, yellow, pink, indigo } from "@material-ui/core/colors";

const OccupancyByAccessGroup = ({ AGData, contractTotal }) => {
  const classes = useStyles();
  let colorIndex = -1;
  let backgroundColors = [];
  const availableBGColors = [purple[400], cyan[400], orange[500], teal[600], green[500], red[400], blue[400], lightGreen[400], yellow[400], pink[400], indigo[400]]; 
  let labels = [];
  let typeCounts = [];

  if(contractTotal == undefined) contractTotal = 0;

  if (AGData != undefined && AGData?.length > 0) {
    AGData.sort((a, b) => parseFloat(a.occupiedSpaces) - parseFloat(b.occupiedSpaces));
    AGData.reverse();

    AGData.forEach((x, index) => {
        let color = availableBGColors[ index  % (availableBGColors.length) ]
        backgroundColors.push(color)
        contractTotal -= x.occupiedSpaces;
        typeCounts.push(x.occupiedSpaces);
        labels.push(x.accessGroup?.name);
    });
  } else {
    backgroundColors = [grey[400]]
  }

  typeCounts.push(contractTotal)
  labels.push("Spaces available")
  backgroundColors.push(grey[400])
  

  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: backgroundColors,
        data: typeCounts,
      },
    ],
  };

  const options = {
    cutoutPercentage: 55,
    responsive: false,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    elements: {
      center: {
        text: "",
      },
    },
  };

  return (
    <>
      <Paper
        elevation={5}
        variant="outlined"
        className={classes.OccupancyByPakerTypePaper}
      >
        <Grid container className={clsx("occupancy-by-tracked-access-group", classes.containerMargin)}>
          <Grid item xs={12}>
            <Typography className={clsx("title", classes.title)} color="primary">
              Occupancy by Tracked Access Group
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={clsx("donut-chart", classes.chartGrid)}
          >
            <Doughnut data-id="access-group-donut" data={data} options={options} />
          </Grid>
          <Grid
            item
            className={classes.tableGrid}
            lg={5}
            md={5}
            sm={11}
            xs={11}
          >
            {AGData?.length > 0 ? (
              <Table size="small" className={clsx(`access-group-table`)}>
              <TableBody>
                {AGData.slice(0, 4).map((row) => {
                  const name = row.accessGroup?.name;
                  if (row.description?.toLowerCase() != "facility") {
                    colorIndex += 1;
                    return (
                      <TableRow key={name} className={clsx(`${name}_tableRow`)}>
                        <TableCell size="small" padding="none">
                          <FiberManualRecordIcon
                            style={{ color: backgroundColors[colorIndex] }}
                          />
                        </TableCell>
                        <TableCell className={clsx(`${name}_description`, classes.tableFont)} padding="none">
                          {name}
                        </TableCell>
                        <TableCell
                          className={clsx(`${name}_value`, classes.tableFont)}
                          data-id="tableDescription"
                        >
                          {row.occupiedSpaces}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
            ) : (
              <Typography>No Credentials for Access Groups in Use</Typography>
            )}  
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default OccupancyByAccessGroup;
