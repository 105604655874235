import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    width: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "4em",
    paddingLeft: "1em",
    paddingBottom: "0.5em",
    paddingTop: "0.5em",
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  name: {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
  },
  totalsPanel: {
    display: "flex",
    alignItems: "center",
  },
  noData: {
    fontStyle: "italic",
    color: theme.palette.grey[500]
  }
}))