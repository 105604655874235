import { Box, Divider, Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useStyles } from "./TotalsPercentages.styles";
import { getThresholdColor } from "../Utils";

const TotalsPercentages = ({ counts, thresholds }) => {

  const classes = useStyles();

  if (!counts || counts.length <= 0) {
    return <></>
  }

  return (
    <div className={classes.container}>
    <Hidden only={["sm", "xs"]}>
      {counts.map((row) => (
        <Box className={classes.totalsContainer} key={`${row.description}_headerPecentage`}>
          <Typography
            style={{
              color: getThresholdColor(thresholds, row.description, row.occupied, row.totalSpaces)
            }}
            className={clsx(
              `${row.description}_headerPecentage`,
              classes.percentage
            )}
          >
            {Math.trunc(
              (row.occupied /
                (row.totalSpaces == 0 ? 1 : row.totalSpaces)) *
                100
            )}
            %
          </Typography>
          <Divider />
          <Typography
            className={clsx(
              `${row.description}_headerDescription`,
              classes.description
            )}
          >
            {row.description}
          </Typography>
        </Box>
      ))}
    </Hidden>
  </div>
  );
}

export default TotalsPercentages;