import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/* Components */
import {
  Menu,
  MenuItem,
  Badge,
  IconButton,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@material-ui/core";
import { faPhoneVolume } from "@fortawesome/pro-duotone-svg-icons";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";

/* Hooks */
import useHowler from "../../hooks/useHowler";
import useEntitySettings from "../../hooks/useEntitySettings";

/* State */
import { useSelector, shallowEqual } from "react-redux";

/* Utilities */
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

/* Style */
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./NotificationIcon.style";
import clsx from "clsx";
import useCallCenterState from "../../hooks/useCallCenterState";

const NotificationIcon = ({ title, alt }) => {
  const classes = useStyles();
  const theme = useTheme();
  const calls = useSelector((state) => _.orderBy(state.callcenter.Queue, ["callStart"], ["desc"]), shallowEqual);
  const { play, Howler, playing, looping, stop } = useHowler({
    src: "/audio/alert-chime-01.mp3",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { getSettingValue } = useEntitySettings();
  const { isUserViewingCall, newCall } = useCallCenterState();

  useEffect(() => {
    if (newCall && !isUserViewingCall) {
      play({ loop: isContinuousRingDeviceInQueue() });
    } else stop();
  }, [newCall]);

  useEffect(() => {
    if (!isUserViewingCall && isContinuousRingDeviceInQueue()) {
      play({ loop: true });
    } else if (isUserViewingCall && looping === true) {
      stop();
    }
  }, [isUserViewingCall]);

  const isContinuousRingDeviceInQueue = () => {
    let inQueue = false;
    for (let call of calls) {
      const continuous = getSettingValue(
        call.deviceID,
        "intercom.continuousring"
      );
      if (!continuous || continuous == "false") continue;
      inQueue = true;
      break;
    }
    return inQueue;
  };

  const handleClick = (event) => {
    if (calls.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handleSelection = (qi) => {
    history.push("/call");
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Howler identifier="notification-howler" />
      <IconButton color="inherit" title={title} onClick={handleClick}>
        <Badge
          badgeContent={calls.length}
          color="secondary"
          invisible={calls.length === 0}
        >
          <FontAwesomeIcon
            icon={faBell}
            className={clsx([
              playing ? classes.makeItNoticable : classes.makeItNormal,
              "notification-icon",
            ])}
          />
        </Badge>
      </IconButton>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Typography style={{ padding: 6, paddingLeft: 14 }} color="primary">
          <FontAwesomeIcon icon={faBell} style={{ marginRight: 15 }} />
          Notifications
        </Typography>
        <Divider />
        {calls?.map((qi, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handleSelection(qi);
            }}
          >
            <ListItemIcon style={{ marginRight: -20 }}>
              <FontAwesomeIcon style={{ color: "red" }} icon={faPhoneVolume} />
            </ListItemIcon>
            <ListItemText>
              <Box style={{ position: "relative" }}>
                <Typography color="primary">Call Center</Typography>
                
              </Box>
              <Typography style={{ fontSize: "smaller" }}>
                <span style={{ fontWeight: "bold" }}>{qi.deviceName}</span>
                &nbsp;is requesting help.
              </Typography>
              <Typography
                  style={{ fontSize: "smaller", textAlign: "right" }}>
                  <Moment
                    style={{ color: theme.palette.text.disabled }}
                    date={moment.utc(qi.callStart)}
                    format="hh:mm a - MM/DD/YY"
                    local
                  />
                </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NotificationIcon;

NotificationIcon.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string,
};
