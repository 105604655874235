import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  preferencesContainer: {
    padding: 20, width: 600,
  },
  centerContentColumn: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  centerContentRow: {
    display: "inline-block",
    verticalAlign:"middle",
    marginLeft:20,
    [theme.breakpoints.down("md")]: {
      
    },
  },
  padding: {
      paddingLeft: "10%"
  },
  bottomMargin: {
      marginBottom: theme.spacing(1)
  },
  btnControl: {
    marginTop:10,
    marginLeft:10,
  }
}));
