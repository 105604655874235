import React from "react";
import { Doughnut, Chart } from "react-chartjs-2";
import { Typography, Grid } from "@material-ui/core"
import clsx from "clsx";
import { grey } from "@material-ui/core/colors"
import { useStyles } from "./styles";
import { getThresholdColor } from "../Utils";

const OccupancyDoughnut= ({occupancyData, thresholds}) => {
    const classes = useStyles();
    const doughtnutLabels = ["Occupied"];
    const doughnutCounts = [occupancyData.occupied]

    if(occupancyData.occupied < occupancyData.totalSpaces){
      doughtnutLabels.push("Spaces available");
      doughnutCounts.push(occupancyData.totalSpaces - occupancyData.occupied);
    } 

    var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;

    //This is for the percentage in the center of the doughnut.
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function () {
        originalDoughnutDraw.apply(this, arguments);
  
        var chart = this.chart.chart;
        var ctx = chart.ctx;
        var width = chart.width;
        var height = chart.height;

        var fontSize = (height / 125).toFixed(2);
        ctx.font = fontSize + "em Verdana";
        ctx.textBaseline = "middle";

        if(this.chart.config.options.elements.center != undefined) {
          var text = this.chart.config.options.elements.center.text,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height + chart.chartArea.top) / 2;
  
        ctx.fillText(text, textX, textY);
        }
      }
    });
    
    const data = {
      labels: doughtnutLabels,
      datasets: [
        {
          backgroundColor: [
            getThresholdColor(thresholds, occupancyData.description, occupancyData.occupied, occupancyData.totalSpaces), //occupied color
            grey[400]  //total spaces available color.
          ],
          data: doughnutCounts
        }
      ]
    };
  
    const options = {
      cutoutPercentage: 55,
      maintainAspectRatio: true,
      legend: {
        display: false
      },
      title: {
        display: true,
        text: [
          occupancyData.description,
          occupancyData.occupied + "/" + occupancyData.totalSpaces
        ],
        fontSize: 16
      }
      ,
      elements: {
        center: {
          text: Math.trunc(occupancyData.occupiedPercent) + "%"  //Percentage in middle of chart
        },
      }
    };
  
    return (
      <Grid item className={clsx(`${occupancyData.description}_doughnutChart`)} data-occupied={occupancyData.occupied} data-available={occupancyData.totalSpaces - occupancyData.occupied} data-supply={occupancyData.totalSpaces} data-percentage={Math.trunc(occupancyData.occupiedPercent)}>
        <Doughnut
          width={230}
          data-id="doughnutChart"
          data={data}
          options={options}
        />
        <Typography
          className={clsx(`${occupancyData.description}_availability`, classes.centerText) }
          data-id="doughnutAvailability"
        >
          Available: {occupancyData.totalSpaces - occupancyData.occupied}
        </Typography>
      </Grid>
    );
}

export default OccupancyDoughnut;