import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
  btnControl: {
    marginTop:10,
    marginLeft:10,
  }
}));