import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { Typography, Grid, Paper } from "@material-ui/core";
import clsx from "clsx";
import {
  purple,
  teal,
  orange,
  green,
  blue,
  red,
} from "@material-ui/core/colors";
import { useStyles } from "./styles";

const OccupancyBarChart = ({ subscriberData }) => {
  const classes = useStyles();
  const labels = [];
  const occupied = [];
  const totals = [];
  const inBGColors = [purple[600],orange[600],teal[600],green[600],blue[600],red[600]]
  const availableBGColors = [purple[200],orange[200],teal[200],green[200],blue[200],red[200]]

  if(subscriberData?.length > 0) {
    subscriberData.sort((a, b) => parseFloat(a.totalCount) - parseFloat(b.totalCount));
    subscriberData.reverse();
    subscriberData.slice(0,6).forEach((element) => {
      labels.push(element.subscriberName);
      occupied.push(element.inCount);
      totals.push(element.totalCount - element.inCount);
    });
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Occupied",
        backgroundColor: inBGColors,
        barThickness: 120 / subscriberData?.slice(0, 6).length,
        data: occupied,
      },
      {
        label: "Available",
        backgroundColor: availableBGColors,
        barThickness: 120 / subscriberData?.slice(0, 6).length,
        data: totals,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
      position: "bottom",
    },
    layout: {
      padding: {
        left: 10,
        right: 30,
        top: 0,
        bottom: 0,
      },
      margin: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            suggestedMax: 10,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  return (
    <>
        <Paper elevation={5} variant="outlined" className={classes.maxWidth}>
          <Grid container className={clsx("third-party-pass-barchart", classes.containerMargin)}>
            <Grid item xs={12}>
              <Typography className={clsx("title", classes.title)} color="primary">
                3rd Party Passes
              </Typography>
            </Grid>

            {subscriberData?.length > 0 ? (
            <Grid item xs={12} className={clsx("bar-chart", classes.BarChart)}>
              <HorizontalBar
                data-id="doughnutChart"
                data={data}
                options={options}
              />
            </Grid>
            ) : (
              <Grid item xs={12}  >
                <Typography className={clsx("no-data-message", classes.centerContentRow, classes.NoBarChart)}>
                  There is no third party pass data to display
                  </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
    </>
  );
};

export default OccupancyBarChart;
