
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    root: { 
        denomRow: {
            borderTopWidth: '0px',
            paddingTop: '4px',
            paddingBottom: '4px',
            borderTop: '0px solid white',
        },
    },
    makeItNoticable: {
        animation: "tada",
        animationDuration: "1s",
      },
    makeItNormal: {
        animation: "",
        animationDuration: "0s",
    }
}));


