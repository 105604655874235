import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  Accordion,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useOccupancyDataContext } from "../OccupancyDataProvider";
import { useStyles } from "./FacilityDisplay.styles";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import TotalsPercentages from "../Shared/TotalsPercentages/TotalsPercentages.index";
import { CountsPanel } from "../Shared/CountsPanel/CountsPanel.index";
import Title from "../../../Title";

export default function FacilityDisplay() {
  
  // Context provider state
  const {
    countDataByFacility,
  } = useOccupancyDataContext();
  const [counts, setCounts] = useState([]);

  // local state, ui controls
  const [thresholdData, setThresholdData] = useState({
    preferencesData: {
      occupancythemes: [
      {
        name: "contract",
        low: { name: "low", value: 0, color: "#4caf50" },
        medium: { name: "medium", value: 50, color: "#ffeb3b" },
        high: { name: "high", value: 75, color: "#d50000" }
      },
      {
        name: "transient",
        low: { name: "low", value: 0, color: "#4caf50" },
        medium: { name: "medium", value: 50, color: "#ffeb3b" },
        high: { name: "high", value: 75, color: "#d50000" }
      },
      {
        name: "facility",
        low: { name: "low", value: 0, color: "#4caf50" },
        medium: { name: "medium", value: 50, color: "#ffeb3b" },
        high: { name: "high", value: 75, color: "#d50000" }
      }
    ]}
  });

  // framework hooks
  const classes = useStyles();

  // AmanoOne hooks
  const { facilityID } = useCurrentFacility();
  const entitySettingData = useSelector(
    state =>
      state?.entities?.Context?.settings?.find(x => x.name == "preferences")
        ?.value
  );
  const { hasPermissions } = useHasPermissions();
  const occupancyView = hasPermissions(["dashboard.occupancy.view"]);

  useEffect(() => {
    try {
      setThresholdData(JSON.parse(entitySettingData));
    } catch (err) {
      console.log("Error parsing JSON data.", err);
    }
  }, [entitySettingData]);

  useEffect(() => {
    setCounts(countDataByFacility[facilityID]);
  }, [countDataByFacility, facilityID])

  if (!occupancyView) return <></>;

  return (
    <Accordion defaultExpanded={true} className={clsx("occupancy-panel")}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon className={clsx("expand-occupancy", "button")} />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Title cssSelector="title" data-id="titleCard">
          Occupancy
        </Title>
        {!counts || counts.length <= 0 ? (
          <Typography
            className={clsx(
              "no-data",
              "text",
              classes.maxWidth,
              classes.centerContentColumn
            )}
          >
            There is no occupancy data to display
          </Typography>
        ) : (
          <TotalsPercentages counts={counts} thresholds={thresholdData} />
        )}
      </AccordionSummary>
      <CountsPanel facilityID={facilityID} thresholds={thresholdData} />
    </Accordion>
  );
}