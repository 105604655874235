import React from "react";
import { memo } from "react";
import { Box } from "@material-ui/core";

const TabPanel = ({...props}) => {
    const { children, index, value, ...other } = props;
    if (index !== value) {
     return <></>;
    }

    return (
      <div
        role="tabpanel"
        id={`occupancy-tabpanel-${index}`}
        aria-labelledby={`occupancy-tab-${index}`}
        style={{ display: index == value ? 'block' : 'none' }}
        {...other}
      >
        <Box p={3}>
          {children}
        </Box>
      </div>
    );
  }

  export default TabPanel;
  export const MemoizedTab = memo(TabPanel);