import React from "react";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Button, Divider, Typography } from "@material-ui/core";
import { useStyles } from "./TotalOccupancyStyle.js";
import Loading from "../../Loading";
import * as Yup from "yup";
import SaveIcon from "@material-ui/icons/Save";
import BlockIcon from "@material-ui/icons/Block";
import CancelIcon from "@material-ui/icons/Cancel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlash } from "@fortawesome/pro-regular-svg-icons";
import { faBan } from "@fortawesome/pro-light-svg-icons";

const OccupancySupplyForm = ({
  schema,
  occupancy,
  isLoading,
  saveForm,
  onClose,
  disabled,
}) => {
  const classes = useStyles();

  if (isLoading === true) {
    return <></>;
    //return <Loading />;
  } else {
    return (
            <Grid container style={{ padding: 20, maxWidth: 400 }}>
              <Grid item xs={12} mg={12} lg={12}>
                <Typography variant="h5" color="primary" component="h2">
                  Occupancy Availability
                </Typography>
                <Divider style={{ marginBottom: 15 }}></Divider>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Field
                  variant="outlined"
                  component={TextField}
                  type="number"
                  name="Facility"
                  label="Facility Capacity"
                  data-id="Facility"
                  size="small"
                  className={classes.input}
                  onInput={(e) => {
                    if (!e.target.value.toString().includes(".")) {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }
                  }}
                  disabled={disabled}
                  InputProps={{
                    readOnly: Boolean(disabled),
                    "aria-readonly": Boolean(disabled),
                    disabled: Boolean(disabled),
                  }}
                />
                <Grid item xs={12} md={12} lg={12}>
                  <Field
                    variant="outlined"
                    component={TextField}
                    type="number"
                    name="Contract"
                    label="Contract Spaces"
                    data-id="Contract"
                    size="small"
                    className={classes.input}
                    onInput={(e) => {
                      if (!e.target.value.toString().includes(".")) {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }
                    }}
                    disabled={disabled}
                    InputProps={{
                      readOnly: Boolean(disabled),
                      "aria-readonly": Boolean(disabled),
                      disabled: Boolean(disabled),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Field
                    variant="outlined"
                    component={TextField}
                    type="number"
                    name="Transient"
                    label="Transient Spaces"
                    data-id="Transient"
                    className={classes.input}
                    size="small"
                    onInput={(e) => {
                      if (!e.target.value.toString().includes(".")) {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }
                    }}
                    disabled={disabled}
                    InputProps={{
                      readOnly: Boolean(disabled),
                      "aria-readonly": Boolean(disabled),
                      disabled: Boolean(disabled),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
    );
  }
};

OccupancySupplyForm.defaultProps = {
  disabled: false,
};

export default OccupancySupplyForm;
