import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
  centerContentRow: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  NoBarChart: {
    marginTop: "1%",
    marginBottom: "3%"
  },
  title: {
    fontSize: "24px",
    margin: "2%",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  BarChart: {
    minHeight: "250px"
  }
}));
