import React, { useState, useEffect } from "react";
import { useStyles, StyledTableCell } from "./styles";
import { Container, Typography } from "@material-ui/core";
import { Skeleton } from '@material-ui/lab';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useHubContext from "../../hooks/useHubContext";
import PeripheralService from "../../services/PeripheralService";
import apiClient from "../../auth/apiClient";
import { NON_RESET_COUNTS_TOPIC, NON_RESET_COUNTS_REQUEST_TOPIC, PORTAL_TRIGGER } from "../../constants";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";

const service = new PeripheralService(apiClient);

export const NonResetCounts = ({ entityid }) => {
  const classes = useStyles();
  const { portalHub } = useHubContext();
  const [counts, setCounts] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isLoading, setIsLoading] = useState();

  const fetchPeripheralCounts = async () => {
    setIsLoading(true);
    try {
      var result = await service.getPeripheralCounts(entityid);
      parsePeripheralsForCounts(result.data?.peripherals, result.data?.countTypes);
    } catch(error) {
      enqueueSnackbar("Failed to retrieve peripheral counts", {
        variant: "error", tag: "FailedToRetrievePeripheralCounts"
      });    
    } finally {
      setIsLoading(false);
    }
  }

  const parsePeripheralsForCounts = (peripherals, countTypes) => {
    var newCounts = [];
    countTypes && countTypes.forEach((countType) => {
        newCounts.push({ CountName: countType.name, Total: 0 })
    })
    peripherals && peripherals.forEach((peripheral) => {
      peripheral.peripheralCounts && peripheral.peripheralCounts.forEach((count) => {
          let index = newCounts.findIndex(c => c.CountName === count.name);
          if(index !== -1) {
            newCounts[index].Total = count.count;
            newCounts[index].Serial = peripheral.serial;
          } else {
            newCounts.push({ Serial: peripheral.serial, CountName: count.name, Total: count.count });
          }
      })
    })
    setCounts([...newCounts]);
  }

  const parseMessageForCounts = (message) => {
    setCounts(counts => {
      let newCounts = [...counts];
      let index = newCounts.findIndex(c => c.CountName === message.Name);
      if(index !== -1) {
        newCounts[index].Total += message.Count;
      } else {
        newCounts.push({ Serial: message.Serial, CountName: message.Name, Total: message.Count });
      }
      return [...newCounts];
    });
  }


  useEffect(() => {
    portalHub.subscribe(
      `${entityid}_${NON_RESET_COUNTS_TOPIC}`,
      (message) => {
        var data = JSON.parse(message);
        var message = JSON.parse(data.Message);
        parseMessageForCounts(message);
      }
    );
    
    return () => {
      portalHub.unsubscribe(`${entityid}_${NON_RESET_COUNTS_TOPIC}`);
    };
  }, [portalHub.connection, portalHub.isOpen]);

  useEffect(() => {
    fetchPeripheralCounts();
  }, []);


  return (
    <Container className={classes.tablecontainer}>
      <Table data-id={`cash-status-${entityid}`} className={classes.table} aria-label="spanning table">
        <TableBody>
          <TableRow>
            <TableCell className={classes.subheader}>{isLoading ? <Skeleton /> : "Type"}</TableCell>
            <TableCell align="right"  className={classes.subheader}>{isLoading ? <Skeleton /> : "Count"}</TableCell>
          </TableRow>
          {isLoading ? 
          <>
            <TableRow>
              <StyledTableCell colSpan={12}><Skeleton /></StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={12}><Skeleton /></StyledTableCell>
            </TableRow>
          </> : 
          <>
          {counts?.map((count) => (
            <TableRow key={`nrcount-${count.CountName}`} style={{ borderTopWidth: '0px' }}>
              <StyledTableCell>{count.CountName}</StyledTableCell>
              <StyledTableCell align="right" colSpan={3}>{count.Total}</StyledTableCell>
            </TableRow>
          ))}
          </> 
        }
        </TableBody>
      </Table>
    </Container>
  )
}

export default NonResetCounts;