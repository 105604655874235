import React, { useEffect, useState} from "react";
import OccupancyEditForm from "./OccupancyEditForm";
import CountService from "../../../services/CountService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";

const countService = new CountService(apiClient);

const OccupancyEditor = ({ entityID, occupancySupply, onClose }) => {
    const [demandData, setDemandData] = useState(occupancySupply);
    const enqueueSnackbar = useEnqueueSnackbar();


    useEffect(() => {
        occupancySupply.forEach(row => {
            row.credentialTypeID = row.occupancyType?.toLowerCase() == "transient" ? 1 : row.credentialTypeID;
            row.credentialTypeID = row.occupancyType?.toLowerCase() == "contract" ? 7 : row.credentialTypeID;
            row.credentialTypeID = row.occupancyType?.toLowerCase() == "facility" ? 11 : row.credentialTypeID;
        });

        countService
          .GetOccupancyDemandByEntity(entityID)
          .then((demandResult) => {            
              if(occupancySupply != undefined || occupancySupply.length > 0) {
                  occupancySupply.forEach(row => {
                    let demandData = demandResult.data.find(x => x.occupancyType.toLowerCase() === row.occupancyType.toLowerCase());
                    row.occupied = demandData?.occupied == undefined ? 0 : demandData.occupied;
                    row.occupancyDemandID = demandData?.occupancyDemandId == undefined ? null : demandData.occupancyDemandId;
                    row.available = row.totalSpaces - row.occupied;
                  });
              }
          })
          .catch((err) => {
            if(err.response.status === 404) {
                return;
            } else {
                enqueueSnackbar("Failed to retrieve occupancy demand data", {
                    variant: "error",  tag: "FailedToRetrieveOccDemandData"
                  });
            }
          });

          setDemandData(occupancySupply);
    }, [entityID]);

    const handleAvailableChange = (e) => {
        const value = Math.floor(e.target.value);
        const data = [...demandData];
        const index = data.findIndex(x => x.occupancyType === e.target.name);
        if(value < data[index].totalSpaces && data[index].totalSpaces - value > 0 && value >= 0) {
            data[index].occupied = data[index].totalSpaces - value;
        } else {
            data[index].occupied = value == -1 ? data[index].totalSpaces : 0;
        }

        setDemandData(data);
    }

    const handleOccupiedChange = (e) => {
        const value = Math.floor(e.target.value);
        const data = [...demandData];
        const index = data.findIndex(x => x.occupancyType === e.target.name);
        data[index].available = data[index].totalSpaces - value;
        data[index].occupied = value;

        if(value < data[index].totalSpaces && data[index].totalSpaces - value > 0 && value >= 0) {
            data[index].available = data[index].totalSpaces - value;
            data[index].occupied = value;
        } else {
            data[index].available = value == -1 ? data[index].totalSpaces : 0;
            data[index].occupied = value == -1 ? 0 : data[index].totalSpaces
        }

        setDemandData(data);
    }

    const handleSave = async () => {
        let success = true;
    
        for (const row of demandData) {
            try {
                await countService.UpsertOccupancyDemand(row);
            } catch (error) {
                enqueueSnackbar(`Failed to save occupancy demand data for occupancy type ${row.occupancyType}`, {
                    variant: "error",
                    tag: "FailedToSavedOccDemandData"
                });
                success = false;
            }
        }
    
        onClose(success);
    };

    return (
        <OccupancyEditForm 
        occupancyDemandData={demandData}
        handleOccupied={handleOccupiedChange}
        handleAvailable={handleAvailableChange} 
        onClose={onClose}
        saveDemand={handleSave}
        />
    );
}
  
export default OccupancyEditor;