import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  centerContentRow: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  centerContentColumn: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  tableFont: {
    fontSize: "18px",
  },
  chartGrid: {
    // border: "1px solid black",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "3%",
    marginBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      order: 1,
    },
  },
  title: {
    fontSize: "24px",
    margin: "2%",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  OccupancyByPakerTypePaper: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tableGrid: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      paddingBottom: "10px",
      order: 2,
    },
  },
  reportFontSize: {
    fontSize: "12px"
  },
  pointer: {
    cursor: "pointer",
  }
}));
