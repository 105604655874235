import React from "react";
import { Doughnut, Chart } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Link,
  Typography,
  Paper,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { grey, purple, orange } from "@material-ui/core/colors";

const OccupancyByParkerType = ({
  occupancyData,
  ReportPermission,
  handleReportClick,
}) => {
  const classes = useStyles();
  let totalSpaces = 0;
  let vacancies = 0;
  let colorIndex = -1;
  const backgroundColors = [purple[500], orange[500], grey[400]];
  let labels = [];
  let typeCounts = [];

  if (occupancyData?.length > 0) {
    occupancyData.forEach((x) => {
      if (x.description?.toLowerCase() != "facility") {
        vacancies += x.occupied;
        typeCounts.push(x.occupied);
        labels.push(x.occupancyType);
      } else {
        totalSpaces += x.totalSpaces;
      }
    });
    vacancies = totalSpaces - vacancies;
    typeCounts.push(vacancies);
    labels.push("Vacancies");
  }

  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: backgroundColors,
        data: typeCounts,
      },
    ],
  };

  const options = {
    cutoutPercentage: 55,
    responsive: false,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    elements: {
      center: {
        text: "",
      },
    },
  };

  return (
    <>
      <Paper
        elevation={5}
        variant="outlined"
        className={classes.OccupancyByPakerTypePaper}
      >
        <Grid container className={classes.containerMargin}>
          <Grid item xs={12}>
            <Typography className={clsx("occupancyByParkerTypeTitle", classes.title)} color="primary">
              Occupancy by Parker Type
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={clsx(
              "occupancyByParkerTypeDoughnutChart",
              classes.chartGrid
            )}
          >
            <Doughnut data-id="doughnutChart" data={data} options={options} />
          </Grid>
          <Grid
            item
            className={classes.tableGrid}
            lg={5}
            md={5}
            sm={11}
            xs={11}
          >
            <Table size="small" className={clsx(`occupancyByTypeTable`)}>
              <TableBody>
                {occupancyData.map((row) => {
                  if (row.description?.toLowerCase() != "facility") {
                    colorIndex += 1;
                    return (
                      <TableRow
                        key={row.occupancySupplyID}
                        className={clsx(`${row.occupancyType}_tableRow`)}
                      >
                        <TableCell size="small" padding="none">
                          <FiberManualRecordIcon
                            style={{ color: backgroundColors[colorIndex] }}
                          />
                        </TableCell>
                        <TableCell
                          className={clsx(
                            `${row.occupancyType}_description`,
                            classes.tableFont
                          )}
                          padding="none"
                        >
                          {row.occupancyType}:
                        </TableCell>
                        <TableCell
                          className={clsx(
                            `${row.occupancyType}_value`,
                            classes.tableFont
                          )}
                          data-id="tableDescription"
                          data-value={((row.occupied / totalSpaces) * 100).toFixed(1)}
                        >
                          {((row.occupied / totalSpaces) * 100).toFixed(1)}%
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
                <TableRow className={clsx(`vacancies_tableRow`)}>
                  <TableCell size="small" padding="none">
                    <FiberManualRecordIcon
                      style={{ color: backgroundColors[2] }}
                      className={clsx(`vacancies_ coloredDot`)}
                    />
                  </TableCell>
                  <TableCell
                    className={clsx(`vacancies_description`, classes.tableFont)}
                    padding="none"
                  >
                    Vacancies:
                  </TableCell>
                  <TableCell
                    className={clsx(`vacancies_value`, classes.tableFont)}
                    data-id="tableOccupied"
                  >
                    {((vacancies / totalSpaces) * 100).toFixed(1)}%
                  </TableCell>
                </TableRow>
                {ReportPermission && (
                  <TableRow>
                    <TableCell size="small" padding="none"></TableCell>
                    <TableCell colSpan={4}>
                      <Link
                        color="primary"
                        className={clsx(
                          "report-link",
                          classes.centerText,
                          classes.pointer,
                          classes.reportFontSize
                        )}
                        onClick={handleReportClick}
                      >
                        Count Activity Differential Report
                      </Link>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default OccupancyByParkerType;
