import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
  centerContentColumn: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
}));