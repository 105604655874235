import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  containerStyling: {
    padding: 20, 
    maxWidth: 450
  },
  gridTextField: {
      marginLeft: theme.spacing(1)
  },
  centerContentColumn: {
    textAlign: "left",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
  btnControl: {
    marginTop:10,
    marginLeft:10,
  },
  containerPadding: {
    padding: 20
  }
}));
