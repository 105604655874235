import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  AccordionDetails,
  Drawer,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

import { useStyles } from "./CountsPanel.styles";
import { useOccupancyDataContext } from "../../OccupancyDataProvider";
import useHasPermissions from "../../../../../hooks/useHasPermissions";
import OccupancyTotalsTable from "../OccupancyTotalsTable/OccupancyTotalsTable";
import OccupancyDoughnut from "../OccupancyDoughnut/OccupancyDoughnut";
import OccupancyEditor from "../../../EditOccupancy/OccupancyEditor";
import OccupancyByParkerType from "../OccupancyByParkerTypeChart/OccupancyByParkerType";
import OccupancyByAccessGroup from "../OccupancyByAccessGroup/OccupancyByAccessGroup";
import OccupancyBarChart from "../OccupancyBarChart/OccupancyBarChart";
import TotalOccupancy from "../../../CreateOccupancy/TotalOccupancy";
import Loading from "../../../../Loading";

export const CountsPanel = ({ facilityID, thresholds }) => {

  // Context provider state
  const {
    countDataLoaded,
    countDataByFacility,
    subscriberDataByFacility,
    loadOccupancyDataForFacility,
    requestAccessGroupData,
    accessGroupDataByFacility
  } = useOccupancyDataContext();
  const [counts, setCounts] = useState([]);
  const [subscriberData, setSubscriberData] = useState([]);
  const [accessGroupDataLoaded, setAccessGroupDataLoaded] = useState(false);
  const [accessGroupData, setAccessGroupData] = useState([]);  

  // local state, ui controls
  const [drawerState, setDrawerState] = useState({ open: false });

  // framework hooks
  const classes = useStyles();

  // AmanoOne hooks
  const history = useHistory();
  const { hasPermissions } = useHasPermissions();
  const occupancyWrite = hasPermissions(["dashboard.occupancy.edit"]);
  const occupancyView = hasPermissions(["dashboard.occupancy.view"]);
  const reportPermission = hasPermissions(["CountActivityDiff"]);

  const handleReportClick = (reportName) => {
    history.push(`/reports/CountActivityDiff`);
  };

  const closeDrawer = (demandSaved) => {
    setDrawerState({ open: false });
    if (demandSaved) {
      loadOccupancyDataForFacility(facilityID)
    }
  };

  const handleDrawerOpen = () => {
    if (occupancyWrite) {
      setDrawerState({ open: true });
    }
  };

  useEffect(() => {
    if (countDataLoaded) {
      setCounts(countDataByFacility[facilityID]);
    }
  }, [countDataLoaded, countDataByFacility, facilityID]);

  useEffect(() => {
    if (countDataLoaded) {
      setSubscriberData(subscriberDataByFacility[facilityID]);
    }
  }, [countDataLoaded, subscriberDataByFacility, facilityID]);

  useEffect(() => {
    if (accessGroupDataByFacility[facilityID]?.loaded) {
      setAccessGroupDataLoaded(true);
      setAccessGroupData(accessGroupDataByFacility[facilityID].data);
    }
  }, [accessGroupDataByFacility, facilityID])

  useEffect(() => {
    requestAccessGroupData(facilityID);
  }, [])

  if (!occupancyView) return <></>;

  if (!countDataLoaded || !accessGroupDataLoaded) return <Loading />

  return counts?.length > 0 ? (
    <>
      <AccordionDetails>
        <Paper
          elevation={5}
          variant="outlined"
          className={classes.countDoughnutsPaper}
        >
          <Grid container className={classes.containerMargin}>
            <Grid item className={classes.availabilityTable}>
              <OccupancyTotalsTable countData={counts} />
            </Grid>

            <Grid item className={classes.doughnutContainer}>
              <Grid container className={classes.containerMargin}>
                {counts.map((row) => (
                  <Grid
                    key={`supplyGrid_${row.occupancySupplyID}`}
                    item
                    className={classes.doughnuts}
                    onClick={handleDrawerOpen}
                  >
                    <OccupancyDoughnut
                      key={`supplyDoughtnut_${row.occupancySupplyID}`}
                      occupancyData={row}
                      thresholds={thresholds}
                    />
                  </Grid>
                ))}
                <Drawer anchor="right" open={drawerState.open}>
                  <OccupancyEditor
                    entityID={facilityID}
                    occupancySupply={counts.filter(
                      (x) => x.description?.toLowerCase() != "3rd party passes"
                    )}
                    onClose={closeDrawer}
                  />
                </Drawer>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </AccordionDetails>

      <Grid container>
        <Grid item className={classes.bottomContainers}>
          <AccordionDetails>
            <Grid item xs={12}>
              <Grid container>
                <OccupancyByParkerType
                  occupancyData={counts.filter(
                    (x) => x.description?.toLowerCase() != "3rd party passes"
                  )}
                  handleReportClick={handleReportClick}
                  ReportPermission={reportPermission}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Grid>

        <Grid item className={classes.bottomContainers}>
          <AccordionDetails>
            <Grid item xs={12}>
              <Grid container>
                { accessGroupDataLoaded ? (
                  <OccupancyByAccessGroup
                    AGData={accessGroupData}
                    contractTotal={
                      counts?.find(
                        (x) => x.description?.toLowerCase() == "contract"
                      )?.totalSpaces
                    }
                  />
                ) : (
                  <Loading/>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Grid>
      </Grid>

      <AccordionDetails>
        <OccupancyBarChart subscriberData={subscriberData} />
      </AccordionDetails>
    </>
  ) : (
    <>
      <Typography
        className={clsx("no-data", "text", classes.footerMargin)}
        data-id="noAlertsMessage"
      >
        <>
          There is no occupancy data to display.
          {occupancyWrite && (
            <>
              {" "}
              Add occupancy availability{" "}
              <Link
                component="button"
                variant="body1"
                onClick={handleDrawerOpen}
                className={clsx("click-here", "button", classes.addOccupancyLink)}
              >
                here
              </Link>
              .
            </>
          )}
        </>
      </Typography>
      <Drawer className={classes.drawer} anchor="right" open={drawerState.open}>
        <TotalOccupancy entityID={facilityID} onClose={closeDrawer} />;
      </Drawer>
    </>
  );
};