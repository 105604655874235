import { green } from "@material-ui/core/colors";

export const getThresholdColor = (thresholdData, type, occupied, totalSpaces) => {
  let settingData = thresholdData?.preferencesData?.occupancythemes.find(
    x => x.name == type?.toString().toLowerCase()
  );
  let percentage = (occupied / (totalSpaces == 0 ? 1 : totalSpaces)) * 100;
  let currentColor = green[500];

  if (
    settingData?.low?.value == 0 &&
    settingData?.medium?.value == 0 &&
    settingData?.high?.value == 0
  )
    return currentColor;

  if (
    percentage >= settingData?.low?.value &&
    percentage < settingData?.medium?.value
  ) {
    currentColor = settingData?.low?.color;
  } else if (
    percentage >= settingData?.medium?.value &&
    percentage < settingData?.high?.value
  ) {
    currentColor = settingData?.medium?.color;
  } else if (percentage >= settingData?.high?.value) {
    currentColor = settingData?.high?.color;
  }

  return currentColor;
};