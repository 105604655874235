import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import { Container, Divider, Typography } from "@material-ui/core";
import apiClient from "../../../auth/apiClient";
import CountService from "../../../services/CountService";

const countService = new CountService(apiClient);

export const OccupancyMiniPanel = ({ entityId }) => {
  const [totalCount, setTotalCount] = useState(0);
  const [transientCount, setTransientCount] = useState(0);
  const [contractCount, setContractCount] = useState(0);

  useEffect(() => {
    //fired at render
    //start listening to events related to arming loop on entity id {entityId}
    countService
      .getOccupancyTypes()
      .then((TypeData) => {
        countService
          .GetOccupancySupplyByEntity(entityId)
          .then((occupancyData) => {
            TypeData.data.forEach((type) => {
              if (
                !(
                  occupancyData.data.find(
                    (x) => x.occupancyTypeID == type.occupancyTypeID
                  ) === undefined
                )
              ) {
                if (type.description == "Facility")
                  setTotalCount(
                    occupancyData.data.find(
                      (x) => x.occupancyTypeID == type.occupancyTypeID
                    ).totalSpaces
                  );
                if (type.description == "Transient")
                  setTransientCount(
                    occupancyData.data.find(
                      (x) => x.occupancyTypeID == type.occupancyTypeID
                    ).totalSpaces
                  );
                if (type.description == "Contract")
                  setContractCount(
                    occupancyData.data.find(
                      (x) => x.occupancyTypeID == type.occupancyTypeID
                    ).totalSpaces
                  );
              }
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {
        //failed to receive occupancy types
      });

    //this ABSOLUTELY needs to be in REDUX -- cannot have this making 900 API calls

    return () => {
      //fired at unmounting
      //stop listening to events related to arming loop on entity id {entityId}
    };
  }, []);

  const classes = useStyles();

  return (
    <Container style={{ marginTop: 10, marginBottom: -15 }}>
      <Typography color="primary" className={classes.spacesAvailable}>
        Spaces Available <span style={{ float: "right" }}>{totalCount}</span>
      </Typography>
      <Divider></Divider>
      <div>
        Contract <span style={{ float: "right" }}>{contractCount}</span>
      </div>
      <div>
        Transient <span style={{ float: "right" }}>{transientCount}</span>
      </div>
    </Container>
  );
};

OccupancyMiniPanel.defaultProps = { entityId: undefined };
OccupancyMiniPanel.propTypes = { entityId: PropTypes.string };

export default OccupancyMiniPanel;
