import PropTypes from "prop-types"
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { Typography, Grid, TextField, Divider, MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";

const ThresholdSettings = ({ thresholdData, handleValueChange }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState('');
  const [formData, setFormData] = useState(null);
  const [occupancyTypeID, setOccupancyTypeID] = useState(); 

  const handleObjectchange = (value, name) => {
    let parsedValue = parseInt(value);
    value = isNaN(parsedValue) ? '' : value;

    let index = formData.findIndex(x => x.occupancyTypeID == selected.occupancyTypeID);
    
    if (formData[index]) {
      formData[index].threshold[name] = value;
      setFormData([...formData]);
    }
  }

  useEffect(() => {
    if(!formData) return;
    let data = [ ...formData ]
    let selectedObject
    if(!occupancyTypeID) {
      selectedObject = data.find(x => x?.threshold?.thresholdsId >= 0)
    } else {
      selectedObject = data.find(x => x.occupancyTypeID == occupancyTypeID)
    }

    if(selectedObject == undefined) {
      selectedObject = data?.find(x => x.description == "Transient");
    }
    
    setSelected({ ...selectedObject })
    handleValueChange(selectedObject)
  }, [occupancyTypeID, formData])

  useEffect(() => {
    if(thresholdData == undefined || thresholdData == null) return;
    setFormData([ ...thresholdData ])
  }, [thresholdData])

  const selectList = () => {
    if(Object.keys(selected).length <= 0) return;
    return (
      <>
      <Grid item lg={6}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="threshold-label">Select a threshold</InputLabel>
          <Select
          value={selected?.description}
          renderValue={() => selected?.description}
          variant="outlined"
          labelId="threshold-label"
          label="Select a threshold"
          onChange={e => setOccupancyTypeID(e.target.value)}
          >
            {formData?.map(row => {
              return (
                <MenuItem value={row.occupancyTypeID} key={row.occupancyTypeID}>{row.description}</MenuItem>
              )
            })}
        </Select>
        </FormControl>
      </Grid>
      </>
    )
  }

  return (
    <Grid container item xs={12} className={(clsx("preferences-drawer"), classes.preferencesContainer)}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h5" color="primary" component="h2" data-id="EditFormTitle" name="title">
          Occupancy Thresholds
        </Typography>
        <Divider style={{ marginBottom: 15 }}></Divider>
        {selectList()}
        <Grid item lg={6}>
          <TextField
            inputProps= {{"data-testid":"FullOn"}}
            variant="outlined"
            label="Full on"
            name="highOn"
            value={selected?.threshold?.highOn}
            fullWidth
            onChange={e => handleObjectchange(e.target.value, e.target.name)}
            InputLabelProps={{
              shrink: true,
            }}
            className={clsx("high-on-textfield", classes.textField)}
          />
        </Grid>
        <Grid item lg={6}>
          <TextField
            inputProps= {{"data-testid":"FullOff"}}
            variant="outlined"
            label="Full off"
            value={selected?.threshold?.highOff}
            fullWidth
            name="highOff"
            onChange={e => handleObjectchange(e.target.value, e.target.name)}
            InputLabelProps={{
              shrink: true,
            }}
            className={clsx("high-off-textfield", classes.textField)}
          />
        </Grid>
        
      </Grid>
    </Grid>
  );
};

ThresholdSettings.propTypes = {
  thresholdData: PropTypes.array
}

export default ThresholdSettings;
