import React, { useRef, useEffect } from "react";
import EntityCard from "../EntityCard";

const CardCarrier = (props) => {
  function RefreshCarrier() {
    if (!carrier) return;
    var position;
    if (!placed && props.card.parent) {
      position = props.card.parent.position;
    } else {
      position = props.card.position;
    }
    carrier.style.top = position.y + "px";
    carrier.style.left = position.x + "px";
  }

  function Resize(dimensions) {
    props.card.size.x = dimensions.width;
    props.card.size.y = dimensions.height;
    if (props.card.callbacks.onResize) props.card.callbacks.onResize();
  }

  var styles = {
    position: "absolute",
    transitionDuration: "0.0s",
    opacity: "0",
  };

  const carrierRef = useRef(null);
  var carrier = null;
  var placed = false;

  var components = [
    React.createElement(
      "div",
      {
        key: `cardcarrier_${props.card.data.entityid}`,
        ref: carrierRef,
        style: styles,
      },
      React.createElement(EntityCard, {
        key: `card_${props.card.data.entityid}`,
        entityID: props.card.data.entityid,
        onResize: Resize,
        loadEntity: props.card.callbacks.loadEntity,
        onDelete: props.card.callbacks.onDelete,
      })
    ),
  ];

  useEffect(() => {
    carrier = carrierRef.current;
    window.setTimeout(() => {
      carrier.style.transitionDuration = "0.2s";
      carrier.style.opacity = "1";
      placed = true;
      RefreshCarrier();
    }, 50);
  });

  props.card.functions = { RefreshCarrier: RefreshCarrier };
  return components;
};

export default CardCarrier;
