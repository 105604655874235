import React from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";
import Title from "../../Title";
import { useStyles } from "./styles";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";

const OccupancyEditForm = ({
  occupancyDemandData,
  handleOccupied,
  handleAvailable,
  onClose,
  saveDemand
}) => {
  const classes = useStyles();
  
  const drawerButtons = (saveBtn = true) => {
    return (
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        style={{ marginTop: 20 }}
        className={classes.containerPadding}
      >
        <Divider></Divider>
        <Button
          style={{ float: "right" }}
          startIcon={<CancelIcon />}
          className={clsx("cancelBtn", classes.btnControl)}
          data-id="cancelBtn"
          name="cancel"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
        {saveBtn && (
          <Button
            style={{ float: "right" }}
            //   disabled={disabled}
            startIcon={<SaveIcon />}
            color="primary"
            data-id="saveBtn"
            name="Save"
            variant="contained"
            className={clsx("saveBtn", classes.btnControl)}
            onClick={saveDemand}
          >
            Save
          </Button>
        )}
      </Grid>
    );
  };

  const titleText = () => {
    return (
      <Grid container>
        <Grid item xs={12} md={12} lg={12} className={classes.containerPadding}>
          <Typography variant="h5" color="primary" component="h2" data-id="EditFormTitle" name="title">
            Adjust Occupancy
          </Typography>
          <Divider style={{ marginBottom: 15 }}></Divider>
        </Grid>
      </Grid>
    );
  };

  if (occupancyDemandData == undefined || occupancyDemandData.length == 0) {
    return (
      <>
        {titleText()}
        <Typography className={classes.containerStyling} data-id="NoResultText" name="NoResultText">
          There was no occupancy supply data found. You can setup occupancy
          supply for the facility in the overview.
        </Typography>
        {drawerButtons(false)}
      </>
    );
  }

  return (
    <Grid container className={classes.containerStyling}>
      {titleText()}
      {occupancyDemandData.map((row) => (
        <>
          <Grid container className={ classes.containerPadding }>
            <Grid
              item
              xs={4}
              md={4}
              lg={4}
              className={classes.centerContentColumn}
            >
              <Typography>{row.occupancyType}:</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              lg={2}
              className={classes.gridTextField}
              key={row.occupancyType}
            >
              <TextField
                data-id={row.occupancyType + "_occupied"}
                className={clsx(`${row.occupancyType}_occupied`)}
                label="Occupied"
                type="number"
                defaultValue="0"
                value={row.occupied}
                name={row.occupancyType}
                onChange={(e) => handleOccupied(e)}
                inputProps={{
                    step:1,
                    min:0,
                    max:99999
                  }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} className={classes.gridTextField}>
              <TextField
                data-id={row.occupancyType + "_available"}
                className={clsx(`${row.occupancyType}_available`)}
                name={row.occupancyType}
                type="number"
                defaultValue="0"
                value={row.totalSpaces - row.occupied}
                label="Available"
                onChange={(e) => handleAvailable(e)}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} className={classes.gridTextField}>
              <TextField
                data-id={row.occupancyType + "_capacity"}
                className={clsx(`${row.occupancyType}_capacity`)}
                name={row.occupancyType}
                label="Capacity"
                value={row.totalSpaces}
                disabled
              />
            </Grid>
          </Grid>
        </>
      ))}
      {drawerButtons()}
    </Grid>
  );
};

export default OccupancyEditForm;
