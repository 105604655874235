import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import FacilityGroupDisplay from "./FacilityGroupDisplay/FacilityGroupDisplay.index";
import FacilityDisplay from "./FacilityDisplay/FacilityDisplay.index";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useOccupancyDataContext } from "./OccupancyDataProvider";
import useHasPermissions from "../../../hooks/useHasPermissions";

export default function OccupancyDashboardWidget() {

  const occupancyView = useHasPermissions(["dashboard.occupancy.view"]);

  const { facilityGroups } = useFlags();
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  const facilities = useSelector((state) => state.entityScope.selected) ?? [];

  const { 
    loadOccupancyTypes, 
    occupancyTypesLoaded,
    loadOccupancyData,
  } = useOccupancyDataContext();

  useEffect(() => {
    if (!occupancyView) { return; }
    loadOccupancyTypes();
  }, []);

  useEffect(() => {
    if (!occupancyView || !occupancyTypesLoaded) { return; }
    loadOccupancyData(facilities);
  }, [occupancyTypesLoaded, facilities]);

  if (!occupancyView) { 
    return <></>;
  }

  return (facilityGroups && facilityGroupID) 
    ? (
      <FacilityGroupDisplay />
    ) : (
      <FacilityDisplay />
    );
}