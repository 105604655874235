import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  container: {
    width: "100%",
    textAlign: "right",
    aligntItems: "right",
    paddingTop: 15
  },

  description: {
    minHeight: 10,
    maxHeight: 20,
    textAlign: "center",
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },

  percentage: {
    textAlign: "center",
    fontSize: "2vw"
  },

  totalsContainer: {
    marginTop: -14,
    maxWidth: 130,
    minWidth: 120,
    display: "inline-block",
    border: "1px solid #fff",
    minHeight: 40,
    maxHeight: 80,
    borderColor: theme.palette.action.hover,
    marginRight: theme.spacing(1),
  },
}));