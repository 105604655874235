import React from "react";
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";

const OccupancyTotalsTable = (occupancyData) => {
    const classes = useStyles();
    let totalSpaces = 0;
    let spacesAvailable = 0;
    let spacesOccupied = 0;
    let index = -1;
    if(occupancyData?.countData != undefined || occupancyData?.countData.length > 0) {
      index = occupancyData?.countData.findIndex(x => x.description?.toLowerCase() == "facility");
    }

    totalSpaces = index === -1 ? 0 : occupancyData.countData[index].totalSpaces; 
    spacesOccupied= index === -1 ? 0 : occupancyData.countData[index].occupied;
    spacesAvailable = totalSpaces - spacesOccupied;

    return (
      <Table size="small" style={{marginLeft: '5%'}}>
        <TableBody>
          <TableRow>
            <TableCell style={{ minWidth: "200px"}} className={clsx("totalSpacesText", classes.tableFont)}>Total spaces:</TableCell>
            <TableCell className={ clsx("totalSpacesCount", classes.tableFont )} data-id="tableDescription" data-value={totalSpaces}>{totalSpaces}</TableCell>
          </TableRow>
  
          <TableRow>
            <TableCell className={ clsx("spacesOccupiedText", classes.tableFont )}>Spaces occupied:</TableCell>
            <TableCell className={ clsx("spacesOccupiedCount", classes.tableFont )} data-id="tableTotalSpaces" data-value={spacesOccupied}>{spacesOccupied}</TableCell>
          </TableRow>
  
          <TableRow>
            <TableCell className={ clsx("spacesAvailableText", classes.tableFont )}>Spaces available:</TableCell>
            <TableCell className={ clsx("spacesAvailableCount", classes.tableFont )} data-id="tableOccupied" data-value={spacesAvailable}>{spacesAvailable}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  export default OccupancyTotalsTable;