import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import { useStyles } from "./styles";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

export const newPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("Required"),
  firstName: Yup.string()
  .trim("No leading or trailing whitespaces...")
  .min(1,"First Name can not be empty")
  .required("Required"),
  lastName: Yup.string()
  .trim("No leading or trailing whitespaces...")
  .min(1,"Last Name can not be empty")
  .required("Required")
});

const NewPassword = ({ onSubmit, cognitoErrors }) => {
  const classes = useStyles();
  const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(newPasswordSchema)
  });
  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(async (values) => await onSubmit(values))}
      noValidate
    >
      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => <TextField
          {...field}
          className={classes.newPassword}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="New Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={!!errors.newPassword || !!cognitoErrors?.newPassword}
          helperText={(errors.newPassword && errors.newPassword.message) || (cognitoErrors?.newPassword && cognitoErrors.newPassword.message)}
        />
        }
      />
      <Grid
        container
        className={classes.nameContainerGrid}
      >
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => <TextField
            {...field}
            size="small"
            className={clsx("firstname"), classes.nameTextField}
            label="First Name"
            variant="outlined"
            error={!!errors.firstName}
            helperText={errors.firstName && errors.firstName.message}
            inputProps={{ "aria-label": "First Name" }}
          />}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => <TextField
            {...field}
            size="small"
            className={clsx("lastname"), classes.nameTextField}
            label="Last Name"
            variant="outlined"
            error={!!errors.lastName}
            helperText={errors.lastName && errors.lastName.message}
            inputProps={{ "aria-label": "Last Name" }}
          />}
        />
      </Grid>

      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          className={clsx(`signin-button`)}
        >
          Sign In
        </Button>
        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </form>
  );
};

export default NewPassword;
