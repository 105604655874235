
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    subheader: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.h6,
    },
    root: { 
        denomRow: {
            borderTopWidth: '0px',
            paddingTop: '4px',
            paddingBottom: '4px',
            borderTop: '0px solid white',
        },
    },
    table: {
        
    },
    tablecontainer: {
        //borderColor:theme.palette.primary.main,
        //border: "1px solid",
        margin:5,
        maxWidth:325,
        display: 'flex',
        justifyContent: 'center',
    }
}));

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        borderBottomWidth: "0px",
        paddingTop: "8px",
        paddingBottom: "8px",
    },
}))(TableCell);
