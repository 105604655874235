import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { useStyles } from "./FacilityGroupDisplayStyles";
import Title from "../../../Title";
import TotalsPercentages from "../Shared/TotalsPercentages/TotalsPercentages.index";
import { useOccupancyDataContext } from "../OccupancyDataProvider";
import Loading from "../../../Loading";
import { CountsPanel } from "../Shared/CountsPanel/CountsPanel.index";
import useSettingsContext from "../../../../hooks/useSettingsContext";
import _ from "lodash";
import { MemoizedTab } from "./Tab";

export default function FacilityGroupDisplay() {

  // local state, ui controls
  const [selectedGroup, setSelectedGroup] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedGroup(newValue);
  };
  const [thresholdDataByFacility, setThresholdDataByFacility] = useState([]);
  const defaultThresholdSettings = {
    preferencesData: {
      occupancythemes: [
      {
        name: "contract",
        low: { name: "low", value: 0, color: "#4caf50" },
        medium: { name: "medium", value: 50, color: "#ffeb3b" },
        high: { name: "high", value: 75, color: "#d50000" }
      },
      {
        name: "transient",
        low: { name: "low", value: 0, color: "#4caf50" },
        medium: { name: "medium", value: 50, color: "#ffeb3b" },
        high: { name: "high", value: 75, color: "#d50000" }
      },
      {
        name: "facility",
        low: { name: "low", value: 0, color: "#4caf50" },
        medium: { name: "medium", value: 50, color: "#ffeb3b" },
        high: { name: "high", value: 75, color: "#d50000" }
      }
    ]}
  };

  // redux state
  const facilityList = useSelector(
    (state) => state.entityScope?.selected, shallowEqual
  ) ?? [];

  // framework hooks
  const classes = useStyles();

  // AmanoOne hooks
  const { countDataLoaded, countDataByFacility } = useOccupancyDataContext();
  const { getSettingByName } = useSettingsContext();

  function getTotalsHtml() {
    if (!countDataLoaded) {
      return <Loading />
    }

    return facilityList.map((facility) => 
      <div className={classes.row} key={`${facility.name}-overview`} >
        <div className={classes.name}>
          <Typography variant="h5" color="primary">{facility.name}</Typography>
        </div>

        {!countDataByFacility[facility.id] || countDataByFacility[facility.id].length === 0
          ? <Typography className={clsx(classes.totalsPanel, classes.noData)}>
              No occuancy data to display
            </Typography>
          : <TotalsPercentages className={classes.totalsPanel} counts={countDataByFacility[facility.id]} thresholds={thresholdDataByFacility[facility.id]}/>
        }

      </div>
    );
  }

  useEffect(() => {
    async function loadThresholdData() {
      const thresholdData = _.cloneDeep(thresholdDataByFacility);
      for (const facility of facilityList) {
        try {
          const response = await getSettingByName(facility.id, "setting.preferences");
          thresholdData[facility.id] = JSON.parse(response.data["setting.preferences"]);
        } catch (err) {
          thresholdData[facility.id] = defaultThresholdSettings;
        }
      }
      setThresholdDataByFacility(thresholdData);
    }
    loadThresholdData();
  }, [facilityList]);

  return (
    <Accordion defaultExpanded={true} className={clsx("occupancy-panel", classes.root)}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon className={clsx("expand-occupancy", "button")} />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Title cssSelector="title" data-id="titleCard">
          Occupancy
        </Title>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.boxContainer}>
          <Tabs 
            value={selectedGroup}
            onChange={handleTabChange}
            unmountOnExit={true}
            mountOnEnter={true}
            transition={false}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            scrollButtons="auto">
              <Tab label="Overview" value={0} data-id="Overview_Tab"/>
                {facilityList.map((facility, index) => 
              <Tab label={facility.name} key={`${facility.id}-tab`} value={index + 1} data-id={facility.id} data-name={facility.name} />
              )}
          </Tabs>
          <MemoizedTab index={0} value={selectedGroup}>
            {getTotalsHtml()}
          </MemoizedTab>
          {facilityList.map((facility, index) => 
            <MemoizedTab index={index + 1} value={selectedGroup} key={`${facility.name}-content`}>
              <CountsPanel facilityID={facility.id} thresholds={thresholdDataByFacility[facility.id]} key={`${facility.name}-counts`}/>
            </MemoizedTab>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}